<template>
  <div>
    <div class="tw-text-center tw-font-bold tw-text-2xl tw-pb-2 tw-pt-8">
      ADDRESS INFORMATION
    </div>
    <Form
      id="add_address_information"
      ref="createForm"
      v-slot="{ errors }"
      autocomplete="off"
      @submit="validateTab"


    >
      <!-- Shipping address starts -->
      <div class="tw-divide-y tw-divide-gray-200">
        <div
          class=" tw-text-base tw-leading-6 tw-space-y-4 tw-text-gray-700 tw-sm:text-lg tw-sm:leading-7"
        >
          <p>On sign up you provided following addresses:</p>

          <address v-if="data.primary_address" class="tw-font-semibold ">
            <span>{{ data.primary_address.address }}, <br></span>
            <span v-if="data.primary_address.suburb">{{ data.primary_address.suburb }},</span>
            <span v-if="data.primary_address.postcode">{{ data.primary_address.postcode }}<span
              v-if="data.primary_address.state">,</span> </span>
            <span v-if="data.primary_address.state">{{ getStateName }} </span>
          </address>

          <p>
            We also need shipping and billing addresses. Please specify below if any of them differ
            from main address:
          </p>

          <div class="tw-text-left tw-font-semibold tw-text-xl">
            SHIPPING ADDRESS
          </div>


          <label class="label-switch switch-success">
            <input
              id="same_as_main_address"
              v-model="isShippingAddressSameAsMainAddress"
              class="switch switch-bootstrap status"
              name="same_as_main_address"
              type="checkbox"
            >
            <span class="lable"> Same as main address</span></label>

          <transition name="fade" >
            <BaseSingleAddressComponent
              ref="shippingAddressRef"
              v-if="!isShippingAddressSameAsMainAddress"
              :address.sync="addressInformation.shippingAddress ?? {}"
              :suburbAndPostCodeInSameRaw="true"/>

          </transition>
        </div>
      </div>
      <!-- Shipping address ends -->

      <!-- Billing address starts -->
      <div class="tw-divide-y tw-divide-gray-200">
        <div
          class=" tw-text-base tw-leading-6 tw-space-y-4 tw-text-gray-700 tw-sm:text-lg tw-sm:leading-7"
        >

          <div class="tw-text-left ">
            <span class="tw-font-semibold tw-text-xl">BILLING ADDRESS </span>
            <br>
            <label class="label-switch switch-success">
              <input
                id="same_as_main_address_billing"
                v-model="isBillingAddressSameAsMainAddress"
                class="switch switch-bootstrap status"
                name="same_as_main_address_billing"
                type="checkbox"
              >
              <span class="lable"> Same as main address</span></label>
          </div>
          <transition name="fade">
            <BaseSingleAddressComponent v-if="!isBillingAddressSameAsMainAddress"
                                        ref="billingAddressRef"
                                        :address.sync="addressInformation.billingAddress ?? {}"
                                        :suburbAndPostCodeInSameRaw="true"/>

          </transition>

        </div>

      </div>
      <!-- Billing address ends -->
      <button ref="submit_add_address" class="d-none" form="add_address_information" type="submit"/>
    </Form>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {maska} from 'maska'
import BaseSingleAddressComponent from "../../../components/commons/BaseSingleAddressComponent";

export default {
  name: "AddressInformationPartial",
  directives: {
    maska
  },
  components: {
    BaseSingleAddressComponent
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isTabValid: false,
      isBillingAddressSameAsMainAddress: true,
      isShippingAddressSameAsMainAddress: false,
      addressInformation: {
        billingAddress: {},
        shippingAddress: {}
      }
    }
  },
  methods: {
    isAValidTab() {
      let hasErrorsInShippingAddress = false
      let hasErrorsInBillingAddress = false

      if(!this.isShippingAddressSameAsMainAddress){
        this.$refs.shippingAddressRef.validateForm()
        const address = this.addressInformation.shippingAddress
        hasErrorsInShippingAddress = !address.address || !address.suburb || !address.postcode || !address.state
      }
      if(!this.isBillingAddressSameAsMainAddress){
        this.$refs.billingAddressRef.validateForm()
        const address = this.addressInformation.billingAddress
        hasErrorsInBillingAddress = !address.address || !address.suburb || !address.postcode || !address.state
      }

      if(!hasErrorsInShippingAddress && !hasErrorsInBillingAddress)  this.$refs.submit_add_address.click()

    },
    validateTab() {
      this.$emit('validTab', 'address_information')
    },
    getAddressInformation() {
      let validatedData = {}
      validatedData.addressInformation = this.addressInformation
      validatedData.isBillingAddressSameAsMainAddress = this.isBillingAddressSameAsMainAddress
      validatedData.isShippingAddressSameAsMainAddress = this.isShippingAddressSameAsMainAddress
      return validatedData
    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
    getStateName() {
      return _.find(this.state, {state_id: this.data.primary_address.state})?.name
    }
  },
  mounted() {
    this.$store.dispatch('registration/getState');

    //To remove initial errors if there are any
    // this.addressInformation.shippingAddress.address = '';

    /* this.$nextTick(() => {
       this.errors.clear();
       this.$nextTick(() => {
         this.$validator.reset();
       });
     });*/
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
